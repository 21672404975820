.login-container {
    font-family: 'Lato', sans-serif;
    border-radius: 5px;
    padding: 40px;
    width: 539px;
    min-height: 325px;
    margin: 100px auto;
    background-color: #f5f5f5;
    text-align: center;
}

    .login-container h2 {
        color: #36a67c;
    }
    .login-container input {
        margin: 0.8em auto;
        padding-left: 5px;
        width: 52%;
        border-radius: 5px;
        color: #616161;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        display: block;
        height: 2.4em;
    }
.login-form button {
    padding-left: 0px;
    background-color: #507B98;
    font-weight: bold;
    color: #ffffff;
    border: none;
    width: 25%;
    border-radius: 5px;
    padding: 7px;
}