@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700|PT+Serif:400,400i,700");

.button, a.button {
    -o-transition: .2s;
    -ms-transition: .2s;
    -moz-transition: .2s;
    -webkit-transition: .2s;
    transition: .2s;
}

.full {
    *zoom: 1;
}

    .full:before, .full:after {
        content: " ";
        display: table;
    }

    .full:after {
        clear: both;
    }

.flex {
    display: flex;
    justify-content: space-between;
}

    .flex.header {
        width: 90%;
        max-width: 1206px;
        margin: 0 auto;
        height: 70px;
    }

.inner-grid {
    display: grid;
    grid-column-gap: 20px;
}

.no-gap-grid {
    display: grid;
    grid-column-gap: 6px;
}

.grid-wrapper {
    display: grid;
    grid-column-gap: 20px;
    width: 90%;
    max-width: 1206px;
    margin: 0 auto;
}

.full {
   /* width: 90%;*/
    max-width: 1206px;
    margin: 0 auto;
    margin-bottom: 150px;
    margin-top: 64px;
}

.one {
    grid-template-columns: 1fr;
}

.two {
    grid-template-columns: 1fr 1fr;
}

.three-and-one {
    grid-template-columns: 3fr 1fr;
}

.one-and-three {
    grid-template-columns: 1fr 3fr;
}

.three {
    grid-template-columns: 1fr 1fr 1fr;
}

.four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.five {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.one > * {
    float: left;
    width: 100%;
}

.two > * {
    float: left;
    width: 50%;
}

.three-and-one > div:first-child, .one-and-three > div:last-child {
    float: left;
    width: 75%;
}

.three-and-one > div:last-child, .one-and-three > div:first-child {
    float: left;
    width: 25%;
}

.four > * {
    float: left;
    width: 25%;
}

.three > * {
    float: left;
    width: 33.33%;
}

@supports (display: grid) {
    .one > *,
    .two > *,
    .three > *,
    .four > *,
    .three-and-one > div:last-child,
    .one-and-three > div:first-child,
    .three-and-one > div:first-child,
    .one-and-three > div:last-child {
        width: auto;
    }
}

@media screen and (max-width: 768px) {
    .three-and-one, .one-and-three {
        grid-template-columns: 1fr 1fr;
    }

    .four {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .two, .three-and-one, .one-and-three, .three {
        grid-template-columns: 1fr;
    }

        .three-and-one.halves, .one-and-three.halves {
            grid-template-columns: 1fr 1fr;
        }
}

.dlas-forms input:not([type]),
.dlas-forms input[type="text"],
.dlas-forms input[type="password"],
.dlas-forms input[type="email"],
.dlas-forms input[type="url"],
.dlas-forms input[type="date"],
.dlas-forms input[type="month"],
.dlas-forms input[type="time"],
.dlas-forms input[type="datetime"],
.dlas-forms input[type="datetime-local"],
.dlas-forms input[type="week"],
.dlas-forms input[type="number"],
.dlas-forms input[type="search"],
.dlas-forms input[type="tel"],
.dlas-forms input[type="color"],
.dlas-forms select,
.dlas-forms textarea {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    font-size: 0.8em;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    -webkit-transition: 0.3s linear border;
    -moz-transition: 0.3s linear border;
    -ms-transition: 0.3s linear border;
    -o-transition: 0.3s linear border;
    transition: 0.3s linear border;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

    .dlas-forms input:not([type]):focus,
    .dlas-forms input[type="text"]:focus,
    .dlas-forms input[type="password"]:focus,
    .dlas-forms input[type="email"]:focus,
    .dlas-forms input[type="url"]:focus,
    .dlas-forms input[type="date"]:focus,
    .dlas-forms input[type="month"]:focus,
    .dlas-forms input[type="time"]:focus,
    .dlas-forms input[type="datetime"]:focus,
    .dlas-forms input[type="datetime-local"]:focus,
    .dlas-forms input[type="week"]:focus,
    .dlas-forms input[type="number"]:focus,
    .dlas-forms input[type="search"]:focus,
    .dlas-forms input[type="tel"]:focus,
    .dlas-forms input[type="color"]:focus,
    .dlas-forms select:focus,
    .dlas-forms textarea:focus {
        outline: 0;
        outline: thin dotted \9;
        /* IE6-9 */
        border-color: #507B98;
    }

.dlas-forms input[type="file"]:focus,
.dlas-forms input[type="radio"]:focus,
.dlas-forms input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 1px auto #91cbe0;
}

.dlas-forms .checkbox,
.dlas-forms .radio {
    margin: 0.5em 0;
    display: block;
}

.dlas-forms input:not([type])[disabled],
.dlas-forms input[type="text"][disabled],
.dlas-forms input[type="password"][disabled],
.dlas-forms input[type="email"][disabled],
.dlas-forms input[type="url"][disabled],
.dlas-forms input[type="date"][disabled],
.dlas-forms input[type="month"][disabled],
.dlas-forms input[type="time"][disabled],
.dlas-forms input[type="datetime"][disabled],
.dlas-forms input[type="datetime-local"][disabled],
.dlas-forms input[type="week"][disabled],
.dlas-forms input[type="number"][disabled],
.dlas-forms input[type="search"][disabled],
.dlas-forms input[type="tel"][disabled],
.dlas-forms input[type="color"][disabled],
.dlas-forms select[disabled],
.dlas-forms textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #79797d;
}

.dlas-forms input[readonly],
.dlas-forms select[readonly],
.dlas-forms textarea[readonly] {
    background: #eee;
    /* menu hover bg color */
    color: #777;
    /* menu text color */
    border-color: #ccc;
}

.dlas-forms input:focus:invalid,
.dlas-forms textarea:focus:invalid,
.dlas-forms select:focus:invalid {
    color: #b94a48;
    border: 1px solid #ee5f5b;
}

    .dlas-forms input:focus:invalid:focus,
    .dlas-forms textarea:focus:invalid:focus,
    .dlas-forms select:focus:invalid:focus {
        border-color: #e9322d;
    }

.dlas-forms input[type="file"]:focus:invalid:focus,
.dlas-forms input[type="radio"]:focus:invalid:focus,
.dlas-forms input[type="checkbox"]:focus:invalid:focus {
    outline-color: #e9322d;
}

.dlas-forms select {
    border: 1px solid #ccc;
    background-color: white;
    height: 2.25em;
}

    .dlas-forms select[multiple] {
        height: auto;
    }

.dlas-forms label {
    margin: 0.5em 0 0.2em;
    font-size: 13px;
}

.dlas-forms fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
}

.dlas-forms legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    font-size: 125%;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

.dlas-forms .stacked {
    width: 95.8333%;
}

    .dlas-forms .stacked input:not([type]),
    .dlas-forms .stacked input[type="text"],
    .dlas-forms .stacked input[type="password"],
    .dlas-forms .stacked input[type="email"],
    .dlas-forms .stacked input[type="url"],
    .dlas-forms .stacked input[type="date"],
    .dlas-forms .stacked input[type="month"],
    .dlas-forms .stacked input[type="time"],
    .dlas-forms .stacked input[type="datetime"],
    .dlas-forms .stacked input[type="datetime-local"],
    .dlas-forms .stacked input[type="week"],
    .dlas-forms .stacked input[type="number"],
    .dlas-forms .stacked input[type="search"],
    .dlas-forms .stacked input[type="tel"],
    .dlas-forms .stacked input[type="color"],
    .dlas-forms .stacked select,
    .dlas-forms .stacked label,
    .dlas-forms .stacked textarea {
        display: inline-block;
        margin: 0.5em 0;
        width: 49.5%;
    }

.dlas-forms .multi-row {
    display: inline-block;
    display: grid;
    grid-column-gap: 10px;
    width: 100%;
    max-width: 1206px;
    margin: 0 auto;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

    .dlas-forms .multi-row.no-gap {
        grid-column-gap: 0;
    }

    .dlas-forms .multi-row input:not([type]),
    .dlas-forms .multi-row input[type="text"],
    .dlas-forms .multi-row input[type="password"],
    .dlas-forms .multi-row input[type="email"],
    .dlas-forms .multi-row input[type="url"],
    .dlas-forms .multi-row input[type="date"],
    .dlas-forms .multi-row input[type="month"],
    .dlas-forms .multi-row input[type="time"],
    .dlas-forms .multi-row input[type="datetime"],
    .dlas-forms .multi-row input[type="datetime-local"],
    .dlas-forms .multi-row input[type="week"],
    .dlas-forms .multi-row input[type="number"],
    .dlas-forms .multi-row input[type="search"],
    .dlas-forms .multi-row input[type="tel"],
    .dlas-forms .multi-row input[type="color"],
    .dlas-forms .multi-row select,
    .dlas-forms .multi-row label,
    .dlas-forms .multi-row textarea,
    .dlas-forms .multi-row .radio-box, .dlas-forms .multi-row .search-box, .dlas-forms .multi-row .search-box-expand {
        width: 100%;
        margin: 0.75em 0;
    }

    .dlas-forms .multi-row.no-margin input:not([type]),
    .dlas-forms .multi-row.no-margin input[type="text"],
    .dlas-forms .multi-row.no-margin input[type="password"],
    .dlas-forms .multi-row.no-margin input[type="email"],
    .dlas-forms .multi-row.no-margin input[type="url"],
    .dlas-forms .multi-row.no-margin input[type="date"],
    .dlas-forms .multi-row.no-margin input[type="month"],
    .dlas-forms .multi-row.no-margin input[type="time"],
    .dlas-forms .multi-row.no-margin input[type="datetime"],
    .dlas-forms .multi-row.no-margin input[type="datetime-local"],
    .dlas-forms .multi-row.no-margin input[type="week"],
    .dlas-forms .multi-row.no-margin input[type="number"],
    .dlas-forms .multi-row.no-margin input[type="search"],
    .dlas-forms .multi-row.no-margin input[type="tel"],
    .dlas-forms .multi-row.no-margin input[type="color"],
    .dlas-forms .multi-row.no-margin select,
    .dlas-forms .multi-row.no-margin textarea,
    .dlas-forms .multi-row.no-margin .radio-box, .dlas-forms .multi-row.no-margin .search-box, .dlas-forms .multi-row.no-margin .search-box-expand {
        width: 100%;
        margin: 0;
    }

    .dlas-forms .multi-row.no-margin div {
        padding-bottom: 0;
    }

.dlas-forms .single-row {
    width: 100%;
    display: inline-block;
    display: grid;
    grid-template-columns: 1fr;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

    .dlas-forms .single-row input:not([type]),
    .dlas-forms .single-row input[type="text"],
    .dlas-forms .single-row input[type="password"],
    .dlas-forms .single-row input[type="email"],
    .dlas-forms .single-row input[type="url"],
    .dlas-forms .single-row input[type="date"],
    .dlas-forms .single-row input[type="month"],
    .dlas-forms .single-row input[type="time"],
    .dlas-forms .single-row input[type="datetime"],
    .dlas-forms .single-row input[type="datetime-local"],
    .dlas-forms .single-row input[type="week"],
    .dlas-forms .single-row input[type="number"],
    .dlas-forms .single-row input[type="search"],
    .dlas-forms .single-row input[type="tel"],
    .dlas-forms .single-row input[type="color"],
    .dlas-forms .single-row select,
    .dlas-forms .single-row label,
    .dlas-forms .single-row textarea {
        width: 100%;
        margin: 0.75em 0;
    }

    .dlas-forms .single-row.no-margin input:not([type]),
    .dlas-forms .single-row.no-margin input[type="text"],
    .dlas-forms .single-row.no-margin input[type="password"],
    .dlas-forms .single-row.no-margin input[type="email"],
    .dlas-forms .single-row.no-margin input[type="url"],
    .dlas-forms .single-row.no-margin input[type="date"],
    .dlas-forms .single-row.no-margin input[type="month"],
    .dlas-forms .single-row.no-margin input[type="time"],
    .dlas-forms .single-row.no-margin input[type="datetime"],
    .dlas-forms .single-row.no-margin input[type="datetime-local"],
    .dlas-forms .single-row.no-margin input[type="week"],
    .dlas-forms .single-row.no-margin input[type="number"],
    .dlas-forms .single-row.no-margin input[type="search"],
    .dlas-forms .single-row.no-margin input[type="tel"],
    .dlas-forms .single-row.no-margin input[type="color"],
    .dlas-forms .single-row.no-margin select,
    .dlas-forms .single-row.no-margin textarea,
    .dlas-forms .single-row.no-margin .radio-box, .dlas-forms .single-row.no-margin .search-box, .dlas-forms .single-row.no-margin .search-box-expand {
        width: 100%;
        margin: 0;
    }

    .dlas-forms .single-row.no-margin div {
        padding-bottom: 0;
    }

.dlas-forms label.checkbox {
    margin: 1em 0;
}

.dlas-forms .half {
    grid-template-columns: 1fr 1fr;
    margin: 0;
}

.dlas-forms .third {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
}

.dlas-forms .fourth {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 10px 0;
}

.dlas-forms .fifth {
    grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
    margin: 10px 0;
}

.dlas-forms .fifth-even {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 10px 0;
}

.dlas-forms .fifth-50-1 {
    grid-template-columns: 50% 1fr 1fr 1fr 1fr;
    margin: 10px 0;
}

.dlas-forms .one-three {
    grid-template-columns: 1fr 3fr;
    margin: 0;
}

.dlas-forms .four-one {
    grid-template-columns: 4fr 1fr;
    margin: 0;
}

.dlas-forms .three-one {
    grid-template-columns: 20% 60%;
    margin: 0;
}

.dlas-forms .form-content {
    margin-bottom: 15px;
}

.dlas-forms .form-link {
    display: block;
    font-size: 12px;
    margin: 10px 0;
}

.dlas-forms .date-picker-row {
    position: relative;
}

.dlas-forms .angular-date-picker {
    position: absolute;
    left: 0;
    top: 65px;
    z-index: 999;
}

.dlas-forms form.ng-dirty {
    background: #ffffff;
}

/* end lib-form */
select[disabled] {
    background-image: url(../images/arrow-disabled.svg) !important;
}

input[disabled],
select[disabled],
textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded !important;
    color: #79797d !important;
}

.checkbox-label, .radio-box {
    display: inline-block;
    background: #fdfcfc;
    margin: 0 !important;
    line-height: normal;
    border-radius: 4px;
    padding: 0.5em 10px;
    font-size: 0.8em !important;
    border-radius: 4px;
    -webkit-transition: 0.3s linear border;
    -moz-transition: 0.3s linear border;
    -ms-transition: 0.3s linear border;
    -o-transition: 0.3s linear border;
    transition: 0.3s linear border;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #d4d7dc;
}

    .checkbox-label label, .radio-box label {
        color: #507B98;
    }

        .checkbox-label label input, .radio-box label input {
            margin-right: 5px;
        }

    .checkbox-label span, .radio-box span {
        margin: 0 5px;
    }

    .checkbox-label svg, .radio-box svg {
        fill: #507B98;
    }

.dlas-forms .multi-row .radio-box label {
    margin: 0 5px;
    display: inline-block;
    width: auto;
}

.dlas-forms .multi-row .radio-stacked label {
    margin: 5px;
    display: block;
}

@media screen and (max-width: 768px) {
    .dlas-forms .fourth {
        grid-template-columns: 1fr 1fr;
        margin: 10px 0;
    }

    .dlas-forms .multi-row input:not([type]),
    .dlas-forms .multi-row input[type="text"],
    .dlas-forms .multi-row input[type="password"],
    .dlas-forms .multi-row input[type="email"],
    .dlas-forms .multi-row input[type="url"],
    .dlas-forms .multi-row input[type="date"],
    .dlas-forms .multi-row input[type="month"],
    .dlas-forms .multi-row input[type="time"],
    .dlas-forms .multi-row input[type="datetime"],
    .dlas-forms .multi-row input[type="datetime-local"],
    .dlas-forms .multi-row input[type="week"],
    .dlas-forms .multi-row input[type="number"],
    .dlas-forms .multi-row input[type="search"],
    .dlas-forms .multi-row input[type="tel"],
    .dlas-forms .multi-row input[type="color"],
    .dlas-forms .multi-row select,
    .dlas-forms .multi-row label,
    .dlas-forms .multi-row textarea {
        width: 100%;
        margin: 0.75em 0;
    }
}

@media screen and (max-width: 500px) {
    .dlas-forms .fourth, .dlas-forms .third, .dlas-forms .half {
        grid-template-columns: 1fr;
        margin: 10px 0;
    }
}

@media only screen and (max-width: 480px) {
    button[type="submit"] {
        margin: 0.7em 0 0;
    }

    input:not([type]),
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="week"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    label {
        margin-bottom: 0.3em;
        display: block;
    }
}

.back-link {
    font-size: 0.8em;
    background-color: #fff !important;
    text-decoration: none;
    color: #79797d !important;
    min-width: 0;
    padding: 5px 7px;
    cursor: pointer;
    border: 1px solid #b3b3b3;
}

#form-container .form-section {
    background-color: #f5f5f5;
}

    #form-container .form-section h4 {
        font-size: 1.125em;
        margin-top: 0.665em;
        margin-bottom: 0.665em;
        color: #212121;
        display: inline-block;
    }

        #form-container .form-section h4.alt-h4-spacing {
            margin-bottom: 0.665em;
        }

    #form-container .form-section span {
        font-size: 0.750em;
        color: #333333;
        background-repeat: no-repeat;
        /*float: left;*/
        padding-left: 10px;
    }

    #form-container .form-section input {
        height: 1.5em;
    }

    #form-container .form-section select {
        height: 2.4em;
    }

    #form-container .form-section input, #form-container .form-section select, #form-container .form-section textarea {
        margin-top: 0;
        width: 100%;
        font-size: 0.75em;
        margin: 0.8em auto;
        padding: 5px;
        border-radius: 5px;
        color: #616161;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        display: block;
    }

    #form-container .form-section .alternate-dropdown {
        width: 100%;
        background-color: #507B98;
        color: white !important;
        background-image: url("../images/arrow-alternate.svg");
    }

        #form-container .form-section .alternate-dropdown[disabled] {
            color: #79797d !important;
        }

        #form-container .form-section .alternate-dropdown option {
            background-color: white;
            color: #333333;
        }

        #form-container .form-section .alternate-dropdown.import-exemption-dropdown {
            max-width: 30px;
        }

    #form-container .form-section .jcar-datepicker {
        background-image: url("../images/calendar.svg");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position-x: right;
        cursor: pointer;
    }

    #form-container .form-section .input-container {
        display: inline-block;
        padding-right: 20px;
    }

        #form-container .form-section .input-container .react-datepicker-wrapper {
            display: block;
        }

        #form-container .form-section .input-container.no-padding-right {
            padding-right: 12px;
        }

    #form-container .form-section .section-body {
        display: block;
    }

        #form-container .form-section .section-body .input-container {
            display: block;
        }

        #form-container .form-section .section-body textarea {
            height: 12em;
            margin-left: 0;
            resize: none;
        }

#form-container .table-header {
    margin-top: 30px;
    /*margin-bottom: 30px;*/
}

    #form-container .table-header .headerlink-Button {
        margin-right: 5px;
        margin-left: 2px;
    }

    #form-container .table-header h2, #form-container .table-header h3 {
        margin: 0;
    }

    #form-container .table-header h3 {
        font-size: 1em;
        font-weight: bold;
        color: #507B98;
    }

    #form-container .table-header a {
        margin: 0 15px;
    }

    #form-container .table-header span {
        font-size: 0.75em;
        color: #507B98;
        cursor: pointer;
        vertical-align: middle;
    }

#form-container .submit-section {
    margin-top: 7px;
}

    #form-container .submit-section button {
        width: 100%;
        height: 35px;
        font-size: 1em;
    }

.button-menu {
    padding: 15px 0;
    text-align: right;
}

    .button-menu button {
        min-width: 67px;
        padding: 5px 10px;
        width: auto;
        height: 30px;
        color: white;
        background-color: #62aa77;
    }

        .button-menu button a {
            text-decoration: none;
            color: white;
        }

    .button-menu .alternate-button, .button-menu .alternate-button:hover {
        background-color: white;
        border: 1px solid #e0e0e0;
        color: #757575 !important;
    }

        .button-menu .alternate-button a, .button-menu .alternate-button:hover a {
            padding: 5px 10px;
            color: #757575;
            display: block;
        }

#note-warning {
    display: block;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1em;
    font-size: 1.1em;
}

#note-add-button {
    width: 100%;
    text-align: right;
}

    #note-add-button span {
        float: inherit;
    }

.remove-note-container {
    display: block;
    font-size: 0.75em;
    margin: 0.8em;
    padding: 5px;
    height: 1.5em;
}

.input-error {
    border: 1px solid red !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
}

.error-text {
    color: red;
    margin: 20px;
    font-size: 0.8em;
}

#none-to-report-checkbox {
    margin-bottom: 2.2em;
    margin-bottom: 0.665em;
    font-size: 0.8em;
    display: table;
    padding-right: 30px;
}

    #none-to-report-checkbox input[type=checkbox] {
        display: table-cell;
        vertical-align: middle;
        width: auto;
        height: auto;
        margin-right: 5px;
        padding: 0;
    }

    #none-to-report-checkbox label {
        display: table-cell;
        vertical-align: middle;
    }

.react-datepicker__navigation {
    min-width: 0;
}

.alternate-padding-right {
    padding-right: 8px !important;
}

.select-box {
    font-size: 0.75em;
    min-height: 1.5em;
    margin: 0.8em auto;
    width: 100%;
}

    .select-box .select__control {
        padding: 5px;
        min-height: 0px;
        border: 1px solid #e0e0e0;
    }

    .select-box .select__value-container {
        padding: 0;
    }

        .select-box .select__value-container > div {
            padding: 0;
            margin: 0;
        }

    .select-box .select__dropdown-indicator {
        padding: 0px 8px;
    }

    .select-box .select__clear-indicator {
        padding: 0px 2px;
    }

    .select-box .select__input input {
        margin: 0px auto !important;
    }

@media print {
    body, html {
        height: auto !important;
    }
}

body, html {
    height: 100%;
    margin: 0;
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4;
    color: #212121;
    -webkit-font-smoothing: antialiased;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    text-rendering: optimizeLegibility;
}

.site-header a {
    /*color: white !important;*/
    text-decoration: none;
}

    .site-header a:hover {
        /*color: white !important;*/
    }

.box .box-content.full {
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
}

h1, h2, h3, h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

a, a:link {
   /* color: #245e48;*/
}

    a:focus, a:active, a:hover, a:link:focus, a:link:active, a:link:hover {
        /*color: #256880;*/
    }

header {
    background: #507B98;
    color: #FFFFFF;
}

    header h1, header h4 {
        margin: 0;
    }

    header h1 {
        font-size: 28px;
    }

    header button {
        font-family: "Lato";
        /* padding-top: 47px; */
        padding-bottom: 22px;
        padding-top: 22px;
        border-radius: 0px;
        font-weight: bold;
        font-size: 1em;
        min-width: 130px;
    }
   
.popup-container {
    z-index: 150;
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

    .popup-container .popup {
        position: fixed;
        width: 564px;
        top: 50%;
        left: 50%;
        margin-left: -282px;
        margin-top: -140px;
        border-radius: 5px;
        background-color: white;
        box-shadow: -5px -4px 9px #888888;
    }

        .popup-container .popup .popup-head {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: #36a67c;
            color: white;
            text-align: left;
            padding-left: 10px;
            padding-top: 10px;
            height: 2em;
        }

            .popup-container .popup .popup-head span {
                font-size: 1.2em;
            }

        .popup-container .popup .popup-body {
            text-align: center;
            margin: 40px;
        }

            .popup-container .popup .popup-body span {
                padding: 10px;
                display: block;
            }

        .popup-container .popup .button-menu {
            padding: 15px 10px 15px 0px;
            text-align: right;
        }

            .popup-container .popup .button-menu button, .popup-container .popup .button-menu a {
                min-width: 67px;
                margin-left: 20px;
                padding: 5px 10px;
                width: auto;
                height: 30px;
                color: white;
                background-color: #36a67c;
                vertical-align: text-bottom;
            }

            .popup-container .popup .button-menu a {
                height: 20px;
            }

            .popup-container .popup .button-menu .alternate-button, .popup-container .popup .button-menu .alternate-button:hover {
                background-color: white;
                padding: 5px 10px !important;
                border: 1px solid #e0e0e0;
                color: #757575 !important;
            }

/*BUTTONS*/
.button-link, .button-link:hover {
    color: white !important;
    text-decoration: none;
    text-align: center;
    height: 19px;
    display: inline-block;
}
/*BUTTONS*/
.button-choice {
    font-size: 1.2em;
    margin: 45px;
    border-radius: 7px;
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    text-align: center;
    height: 150px;
    width: 180px;
    float: left;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

    .button-choice:hover {
        background-position: 100% 0;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }

    .button-choice:focus {
        outline: none;
    }

    .button-choice.blue {
        background-image: linear-gradient(to right, #30A4DC, #2783BE, #6cd3d3, #1970AE);
        box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }

    .button-choice.green {
        background-image: linear-gradient(to right, #2BDC7C, #5abe27, #72db93, #12AE4F);
        box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }

    .button-choice.red {
        background-image: linear-gradient(to right, #DC5F3B, #BE4534, #db8572, #AE3418);
        box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }

    .button-choice.orange {
        background-image: linear-gradient(to right, #DCB332, #F1C641, #dba772, #C59C1F);
        box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }

.button, a.button {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 13px;
    background: #507B98;
    display: inline-block;
    border: none;
    padding: 7px 20px;
    white-space: nowrap;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
    text-decoration: none;
    vertical-align: middle;
}

    .button.secondary, a.button.secondary {
        background: #ffffff;
        border: 1px solid #cccccc;
        color: #666666;
        font-weight: normal;
    }

    .button:hover, a.button:hover {
        background: #256880;
        color: #FFFFFF;
    }

        .button:hover.secondary, a.button:hover.secondary {
            background: #ffffff;
            border: 1px solid #cccccc;
            color: #666666;
            font-weight: normal;
        }

    .button:active, a.button:active {
        background: #256880;
        color: #FFFFFF;
    }

        .button:active.secondary, a.button:active.secondary {
            background: #ffffff;
            border: 1px solid #cccccc;
            color: #666666;
            font-weight: normal;
        }

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 1090px;
}

    .flex-row .smaller {
        min-width: 72px;
    }

    .flex-row .small {
        min-width: 155px;
    }

    .flex-row .medium {
        min-width: 350px;
    }

    .flex-row .long {
        min-width: 609px;
    }

.error-box {
    padding: 10px;
}

    .error-box span {
        color: red !important;
    }

.hide {
    display: none;
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

.spacer {
    height: 0.5em;
    width: 100%;
}

h2 {
    font-size: 1.375em;
    color: #36a67c;
    font-weight: normal;
}

button, .button-link {
    background-color: #507B98;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;
    min-width: 120px;
    border-radius: 5px;
    padding: 9px;
    font-size: 0.9em;
}

    .button-link:hover {
        background-color: #7ab6df;
    }

table {
    max-width: 1200px;
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
}

th, td {
    padding: 0.5em;
    border: 1px solid #e0e0e0;
    text-align: left;
    min-width: 20px;
}
   
th {
    background-color: #f4f5f5;
    font-weight: normal;
}

select {
    border: 1px solid #e0e0e0;
    padding: 5px 20px 5px 5px;
    min-width: 150px;
    color: #616161;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 5px;
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    /* remove default arrow */
    background-image: url(../images/arrow.svg);
    /* add custom arrow */
    background-repeat: no-repeat;
    background-position: right center;
}

    select::-ms-expand {
        display: none;
        /* hide     the default arrow in ie10 and ie11 */
    }

.icon {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    display: inline-block;
    cursor: pointer;
}
.fontIcon{
    color: #4297b4;
    width: 76px;
    height: 76px;
    padding-left: 110px;
    
}
.fontIcon:hover{
    color:#3D596B;
}

.edit {
    background-image: url("../images/edit.svg");
}

.destroy {
    background-image: url("../images/destroy.svg");
}

.pdf {
    background-image: url("../images/pdf.svg");
}

.paper {
    background-image: url("../images/paper.svg");
}

.paper-disabled {
    background-image: url("../images/paper-disabled.svg");
}

.print {
    background-image: url("../images/print.svg");
}

.addback {
    background-image: url("../images/addback.svg");
}

.add {
    background-image: url("../images/add.svg");
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
}

.seperator {
    padding: 5px 0px 11px 0px;
}

    .seperator hr {
        border: 1px solid #e0e0e0;
    }

#form-container {
    overflow: hidden;
}

#contract-info {
    float: left;
    width: 48%;
    margin-top: 5px;
    padding: 10px;
}

#agency-info {
    margin-top: 5px;
}

#note-info {
    padding: 10px;
}

#half-left {
    width: 47%;
    float: left;
}

#half-right {
    width: 51%;
    float: right;
}

.new-line {
    white-space: pre-line;
    font-size: 12px;
}

    .new-line span {
        float: left;
    }

.upload-container {
    position: relative;
    input

{
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

button {
    float: left;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
}

}

.input-container label {
    margin-right: 10px;
    font-size: 13px;
}

.input-container.radio label {
    margin-left: 5px;
}

.label-upload {
    cursor: pointer;
    text-align: right;
    color: #FFFFFF;
    line-height: 32px;
    height: 35px;
    overflow: hidden;
    background-image: url("../images/upload3.svg");
    background-size: 15px 15px;
    background-repeat: no-repeat;
    display: block;
    background-position: 12px;
    border-radius: 4px;
    padding: 0 15px 0 0;
}

.upload-input {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.upload-container input {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.fieldset-upload {
    border-width: 0;
    margin-left: -10px;
}

#hidden {
    display: none;
}

#form-Button {
    margin-bottom: 4px;
    background-color: #507B98;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;
    min-width: 120px;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
    height: 35px;
    font-size: 1em;
    text-decoration: none;
}

#link-Button {
    margin-bottom: 4px;
    background-color: #507B98;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;
    min-width: 120px;
    border-radius: 5px;
    padding: 7px;
    font-size: 1em;
    text-decoration: none;
    display: block;
    text-align: center;
}
#link-Button:hover {
    background-color: #7ab6df;
}


.menu-header button:hover {
    background-color: white !important; /* Green */
    color: #1d2c37;
}
.MenuBtn {
    /*padding-top: 46px;*/
    padding-right: 20px;
    /* padding-bottom: 10px;*/
    padding-left: 20px;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;
    //width: 80px;
    display: inline-block;
    text-align: center;
    /* border-radius: 5px;*/
    font-size: 1.0em;
    padding-top: 21px;
    padding-bottom: 20px;
    color: white;
    font-weight: bold;
    text-decoration: none;
}
.MenuBtn:hover {
    background: #fff;
    color: #1d2c37;
}
.btn {
    /*padding-top: 46px;*/
    padding-right: 20px;
    /* padding-bottom: 10px;*/
    padding-left: 20px;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;
    //width: 80px;
    display: inline-block;
    text-align: center;
    /* border-radius: 5px;*/
    font-size: 1.0em;
    padding-top: 21px;
    padding-bottom: 20px;
    color: white;
    font-weight: bold;
}

    .btn:hover {
        background: #fff;
        color: #1d2c37;
    }

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block !important;
    /*  width: 100%;*/
}

.file-button {
    padding-right: 28px;
    width: 100%;
}

.button-download {
    display: block;
    margin-top: 10px;
}

.allPubs {
    float: left;
    font-size: 0.75em;
    margin: 0.8em auto;
    padding: 5px;
    border-radius: 5px;
    color: #616161;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    display: block;
    width: 20px;
}

tr:nth-child(even) {
    background: #f1eee39e
}

tr:nth-child(odd) {
    background: #e9e3d1ba
}

.fOKfgu > .wrapper {
    position: relative
}

.menu-choice {
    display: block;
    /* margin-left: 105px;*/
    margin-top: 10px;
}
    .menu-choice h1 {
        color: #2cbccb;
        font-weight: bold;
        font-size: 2.0em;
    }

.grid-choice {
    display: block;
    /* margin-left: 105px;*/
    margin-top: 10px;
}

    .grid-choice h1 {
        color: #2cbccb;
        font-weight: bold;
        font-size: 2.0em;
    }
.info {
    float: right;
    display: inline-block;
    width: 500px;
    margin-top: 15px;
    text-align: justify;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
.card-title {
    text-align: center;
    font-size: 1.35rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}
.card-text {
    font-family: 'Montserrat', sans-serif;
}
.btn-default {
    background-color: #4297b4;
    color: #fff;
    border-radius: 0.25rem;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    width: 200px;
}

    .btn-default:focus, .focus.btn-default {
        background-color: #6390AD;
        color: #1d2c37;
        outline: 2px dotted #6390AD;
    }

    .btn-default:hover, .hover.btn-default {
        background-color: #3D596B;
        color: #fff;
      
    }

    .btn-default:active, .active.btn-default {
        background-color: #6390AD;
        color: #1d2c37;
    }
.col-lg-4 {
    max-width: 100%;
     /* Adjust margin as needed */
    float: left;
}
@media (min-width: 768px) {
    .menu-choice {
        display: grid;
        /* flex-wrap: wrap; */
        /* justify-content: space-between; */
        grid-template-columns: 24% 24% 24% 24%;
        column-gap: 16px;
    }
}

    @media (max-width: 768px) {
        .menu-choice {
            max-width: 100%;
            grid-template-rows: 24% 24% 24% 24%;
            display: grid;
            row-gap: 20px;
        }
    }


    .carousel .slide .legend1 {
        bottom: 120px;
        opacity: 0.65;
        font-size: 16px;
        padding: 14px;
        position: absolute;
        width: 90%;
        border-radius: 10px;
        background: #000;
        color: #fff;
        text-align: center;
        transition: opacity .35s ease-in-out;
        margin-left: 30px;
    }

        .carousel .slide .legend1:hover {
            background: #000;
            opacity: 0.9;
        }

    .carousel.carousel-slider {
        height: 400px;
    }

    .carousel .thumbs {
        margin-left: 90px;
    }

    /* Footer 
-----------*/
    #footer {
        background: #507B98;
        color: #fff;
        text-align: center;
        padding: 8px 0;
    }

        #footer p {
            margin-bottom: 3px;
            margin-top: 0;
            font-size: 0.8em;
        }

        #footer a {
            background: #507B98;
            color: #fff;
            text-align: center;
            text-decoration: none;
            border-bottom: 1px solid #fff;
        }

            #footer a:hover {
                color: #ddd;
                border-bottom: 1px solid #ddd;
            }

        #footer p.footer-links a {
            margin: 0 10px;
        }

        #footer .copy {
            margin-top: 8px;
        }

    #footer,
    .push {
        height: 100px;
    }
    /* END Footer */
.content {
    min-height: 100%;
    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    /*margin-bottom: -100px;*/
    margin-bottom: 30px;
    margin-top: 10px;
}

    #root,
    .wrapper {
        height: 100%;
    }

    .bWZoud > label {
        background-color: #12AE4F;
    }

    .bWZoud > input {
        background-color: #12AE4F;
    }


    .subTable .rt-tr.-odd {
        background: #25688042;
    }

    /*table */
    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
        background: #34495e;
        color: #ffffff;
        font-size: 14px;
        border-right: 1px solid #fff;
        padding: 8px 5px;
        width: 800px;
    }

        .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
            padding: 10px;
            border-right: 1px solid #fff;
        }

    .rt-tr.-odd {
        background: rgba(0,0,0,.03);
    }

    .rt-td {
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #dadada;
    }

    .ReactTable .rt-thead .rt-tr {
        box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
    }
    /*header collection */
    .header-half {
        margin-top: 50px;
    }

        .header-half h1, h2, h3, h4 {
            color: #34495e;
        }
    /*search toolbar */
    .toolbar {
        display: grid;
        align-items: center;
        background: #f1f1f1;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        padding: 10px;
        margin-top: 0;
    }

        .toolbar.search-bar {
            grid-template-columns: 60% 40%;
        }

            .toolbar.search-bar .dlas-forms {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-column-gap: 10px;
            }

        .toolbar p {
            margin: 5px 0;
        }

        .toolbar .select-box {
            width: 200px;
        }

        .toolbar.search-bar input, .toolbar.search-bar label, .toolbar.search-bar select {
            display: block;
            width: 100%;
        }

    .text-danger {
        color: red;
        margin-left: 10px;
    }

    main {
        min-height: 787px;
    }
    /*qweqweqwe*/

.top-cover {
    position: relative;
    padding: 10%;
    background-size: cover
}
.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.carousel-container {
    width: 100%;
    max-width: 600px; /* Adjust the max width as needed */
}

.info {
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        margin-top: 60px;
    }
    .info {
        max-width: 400px;
    }

    .carousel-container {
        max-width: 100%;
    }
    .fontIcon {
        padding-left: 140px;
    }
    .full {
        margin: 0 15px;
        margin-bottom: 150px;
        margin-top: 64px;
    }
}

@media (max-width: 400px) {
    .content {

    }
}
