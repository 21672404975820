.table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table-filter {
    padding-bottom: 20px;
    font-size: 0.888em;
}

    .table-filter select {
        background-color: white;
    }

#icon-section {
    width: 35px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.table-content {
    font-size: 0.75em;
}

.sort {
    background-size: 10px 18px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: right;
}

.sort-none {
    background-image: url(../images/sort.svg);
}

.sort-asc {
    background-image: url(../images/sort-asc.svg);
}

.sort-desc {
    background-image: url(../images/sort-desc.svg);
}

.year-select {
    width: 40%
}

.table-filter {
    font-size: 0.888em;
   

}
.spanFilter {
    padding-left: 30px;
}

#selectYear {
    display: inline-block;
    margin-left: 60px;
}


.menu-card {
    flex: 1;
    max-width: calc(33.33% - 20px); /* Adjust margin as needed */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .menu-card {
        max-width: 100%;
    }
}